import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const SuccessPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="py-10 pt-md-11 bg-dark">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">
              <h6 className="text-uppercase text-warning mb-5">
                Thank you
              </h6>

              <h2 className="display-3 mb-4">Application Submitted!</h2>

              <p className="font-size-lg">Thank you for your interest in becoming a member at Reno Collective! </p>
              <p className="font-size-lg text-success">We will review your application and contact you about next steps.</p>
              <p className="font-size-lg">If you have any questions or comments, you can contact us at <a href="mailto:hello@renocollective.com" className="text-warning">hello@renocollective.com</a>.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

SuccessPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SuccessPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SuccessPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

SuccessPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SuccessPage

export const SuccessPageQuery = graphql`
  query SuccessPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
